<template>
  <section class="login-cont">
    <el-row class="login-header">
      <el-container>
        <el-row class="login-title">
          <img src="@/assets/icon/loginlogo.png">
        </el-row>
      </el-container>
    </el-row>
    <el-row class="login-box">
       <h2 style="margin-bottom: 30px;">双saas中台营销科技</h2>
       <el-tabs v-model="activeName" >
          <el-tab-pane label="密码登陆" name="first">
             <el-form class="login-form" auto-complete="on" ref="form" :rules="rules" :model="form" style="margin-top: 20px">
                <el-form-item label="" prop="username">
                   <el-input prefix-icon="el-icon-s-custom" :placeholder="$t('msg.inp_login_name')" v-model="form.username" autofocus="true" @keyup.enter.native="reqLogin()"></el-input>
                </el-form-item>
                <el-form-item label="" prop="password">
                   <el-input prefix-icon="el-icon-lock" :placeholder="$t('msg.inp_pwd')" v-model="form.password" type="password" @keyup.enter.native="reqLogin()"></el-input>
                </el-form-item>
                <el-form-item label="" prop="captcha">
                   <el-input class="width-240 code-inp" :placeholder="$t('msg.ver_code')" v-model="form.captcha" maxLength='4' @keyup.enter.native="reqLogin()" clearable></el-input>
                   <img @click="getCaptcha" style="float: right" :src="codeImgSrc" class="code-img" :alt="$t('msg.ver_code')" :title="$t('msg.tab_code')"/>
                </el-form-item>
                <el-button :disabled="!loginState" type="primary" class="login-btn" @click="reqLogin()">{{loginState ? '登录' : '登录中...'}}</el-button>
             </el-form>
             <el-row class="login-tips" v-if="showErr">
                <p>{{errorTips}}</p>
             </el-row>
             <el-row class="forget_pwd">
                <div class="forget-box">
                   <a class="a-label" href="javascript:;" @click="forget_pwd" v-text="$t('msg.forget_pwd_a')">忘记密码？</a>
                </div>
                <div class="lang-box">
                   <el-select v-model="selectValue">
                      <el-option
                            v-for="item in langOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                      </el-option>
                   </el-select>
                </div>
             </el-row>
          </el-tab-pane>
          <el-tab-pane label="手机号登录" name="second">
             <el-form class="login-form" auto-complete="on" ref="mobileform" :rules="mobileformrules" :model="mobileform" style="margin-top: 20px;">
                <el-form-item label="" prop="mobile">
                   <el-input prefix-icon="el-icon-s-custom" :placeholder="$t('msg.inp_mobile_num')" v-model="mobileform.mobile" autofocus="true"></el-input>
                </el-form-item>
                <el-form-item label="" prop="mobileCaptcha">
                   <el-input class="width-250 code-inp" :placeholder="$t('msg.ver_code')" v-model="mobileform.mobileCaptcha" maxLength='6' @keyup.enter.native="reqMobileLogin()" clearable></el-input>
                   <el-button style="float: right" @click="getMobileCaptcha">获取验证码</el-button>
                </el-form-item>
                <el-button v-if="loginState" type="primary" style="margin-top: 80px;" class="login-btn" @click="reqMobileLogin()" v-text="$t('msg.login')">登录</el-button>
                <el-button v-else disabled type="primary" style="margin-top: 80px;" class="login-btn" @click="reqMobileLogin()" v-text="$t('msg.logging_in')">登录</el-button>
             </el-form>
          </el-tab-pane>
       </el-tabs>
    </el-row>
    <el-row class="copyright">
      Copyright©2011-2021;深圳市丰润达数字科技有限公司;粤ICP备15049273号-1
    </el-row>
  </section>
</template>
<script>
import { urlObj } from '@/api/interface'
import { getNowFormatDateTime } from '@/utils/tools'
export default {
  data () {
    return {
      captchaId: '',       // 验证码ID
      captcha: '',         // 验证码
      loginState: true,    // 登录状态
      mobile: '',          // 手机号
      mobileCaptcha: '',   // 手机验证码
      mobileCaptchaId: '', // 手机验证码ID
      QRcode: [],          // APP二维码
      codeImgSrc: '',      // 二维码路径
      errorTips: '',       // 错误提示
      showErr: false,      // 是否显示错误提示
      activeName:'first',
      form:{
         username: '', // 登录名
         password: '', // 密码
         captcha: '', // 验证码
      },
      mobileform:{
         mobile:'',
         mobileCaptcha:'',
       },
      thisSys: 0, // 当前系统
      title: '达易住智慧酒店管理平台',
      login_success: '登录成功',
      inp_login_name: '请输入用户名！',
      inp_pwd: '请输入密码！',
      pwd_len_6_16: '密码长度在 6 到 16 个字符！',
      inp_code: '请输入验证码！',
      code_error: '验证码错误！',
      u_or_p_err: '用户或密码错误！',
      inp_mobile: '请输入手机号！',
      mobile_error: '手机号格式错误！',
      confirm: '确定',
      selectValue: '', // 语言
      langOpt: [
         {
            value: 'zh_CN',
            label: '中文'
         },
         {
            value: 'en_US',
            label: 'English'
         }
      ],
      mobileformrules:{
         mobile: [
             {required: true, message: '请输入手机号', trigger: 'blur'},
          ],
         mobileCaptcha: [
             {required: true, message: '请输入验证码', trigger: 'blur'},
          ],

       },
       rules: {
          username: [
             {required: true, message: '请输入登陆名', trigger: 'blur'},
          ],
          password: [
             {required: true, message: '请输入密码', trigger: 'blur'},
          ],
          captcha: [
             {required: true, message: '请输入验证码', trigger: 'blur'},
          ],
       }
    }
  },
  created () {
    this.selectValue = !localStorage.lang ? 'zh_CN' : localStorage.lang
    sessionStorage.clear()    // 清除全部sessionStarage
    sessionStorage.setItem('router', '/login')
    sessionStorage.setItem('messageInfo', "1")
  },
  mounted () {
    this.getCaptcha()
  },
  methods: {
    // 获取图形验证码
    getCaptcha () {
      const url = urlObj.verifyCode
      const param = { captchaType: 'LOGIN' }
      this.$axios.get(url, param).then((res) => {
        if (res.success) {
          this.codeImgSrc = 'data:image/png;base64,' + res.records.captcha
          this.captchaId = res.records.captchaId
        }
      })
    },
     // 账号密码请求登录
     reqLogin: function () {
        this.$refs['form'].validate((valid) => {
           if (valid) {
              let url = urlObj.loginToken
              let param = {
                 username: this.form.username,
                 password: this.form.password,
                 loginMethod: 'ACCOUNT',
                 grant_type: 'password',
                 client_id: 'hored',
                 client_secret: '74a6e749620148e4ab2e1bb3b7267afe',
                 captchaId: this.captchaId,
                 captcha: this.form.captcha,
                 captchaType: 'LOGIN',
                 hasImageCode: true
              }
              let headers = {responseType: 'JSON'}
              this.$axios.post(url, param, headers).then(res => {
                 if (res.success) {
                    const {access_token, refresh_token} = res.data
                    const records = res.records
                    sessionStorage.setItem('userInfo', JSON.stringify(records))            // 存储用户信息
                    sessionStorage.setItem('access_token', access_token)
                    localStorage.setItem('refresh_token', refresh_token)
                    sessionStorage.setItem('menuIndex', '0')               // 设置主菜单初始索引值
                    sessionStorage.setItem('pageSize', '10')               // 设置数据列表每页数
                    sessionStorage.setItem('router', '/index')             // 存储当前路由
                    const loginTime = getNowFormatDateTime()  // 记录当前用户登陆时间
                    sessionStorage.setItem('loginTime',loginTime)
                  //  sessionStorage.setItem('loginTime', dateFactory.dateFormat(true))
                    if (records.accountType === 'HOTEL') this.$router.push('/hotel_index')
                    else this.$router.push('/index')
                 } else {
                    // 获取登录失败的提示信息并显示
                    this.showErr = true
                    this.loginState = true
                    if (res.message.substr(0, 3) === '验证码' ||
                       res.message.substr(0, 3) === '用户名') {
                       this.errorTips = res.message
                    }
                    if (res.message.substr(0, 3) === 'Bad') {
                       this.errorTips = '登录密码错误！'
                    }
                    let timer = setTimeout(() => {
                       this.showErr = false
                       clearTimeout(timer)
                    }, 3000)
                 }
              })
           } else {
              console.log('error submit!!');
              return false;
           }
        })
     },
    // 获取手机验证码
    getMobileCaptcha(){
      // 表单验证
      if(!this.mobileform.mobile) {
        this.$alert(this.inp_mobile, '', {
          confirmButtonText: this.confirm,
          type: 'error'
        })
        return
      }
      const param = {
        type: 'LOGIN',
        mobile: this.mobileform.mobile
      }
      const url = urlObj.mobileCode
      this.$axios.get(url, param).then(res => {
        if (res.success){
          this.mobileCaptchaId = res.records
        }
      })
    },
    // 手机号登录
    reqMobileLogin(){
       this.$refs['mobileform'].validate((valid) => {
          if (valid) {
             const param = {
                client_id: 'hored',
                client_secret: '74a6e749620148e4ab2e1bb3b7267afe',
                grant_type: 'mobile',
                mobile: this.mobileform.mobile,
                loginMethod: 'MOBILE',
                captchaId: this.mobileCaptchaId,
                captcha: this.mobileform.mobileCaptcha,
                captchaType: 'LOGIN'
             }
             const url = urlObj.mobileLogin
             let headers = {responseType: 'JSON'}
             this.$axios.post(url, param, headers).then(res => {
                if (res.success) {
                   console.log(res.success)
                }
             })
          }else{
             console.log('error submit!!');
             return false;
          }
       })
    },
    // 忘记密码
    forget_pwd () {
      sessionStorage.setItem('router', '/forget_pwd')
      this.$router.push('/forget_pwd')
    },
  }
}
</script>

<style lang="scss" scoped>
.login-cont{
   padding: 0;
   box-sizing: border-box;
   height: 100%;
   min-height: 300px;
   min-width: 430px;
   background: #f1f1f1;
   background-size: 100% 100%;
   overflow: auto;
   position: relative;
   .login-header{
      .login-title img{ margin: 30px 60px }
   }
   .login-box{
      width: 520px; margin: 80px auto; border-radius: 10px;
      h2{ text-align: center; color: #037dfb; font-size: 28px }
      /deep/.el-tabs {
         background: #fff;
         border-radius: 10px;
         padding: 40px 60px 0 60px;
         width: 400px;
         line-height: 50px;
         .el-tabs__nav-wrap::after { position: static !important }
         .el-tabs__item{ font-size: 24px; opacity: 0.5; color: #323233 }
         .el-tabs__item.is-active{ font-size: 26px; opacity: 1 }
      }
      .login-form{
         .code-inp{ display: inline-block }
         .code-img { display: inline-block; width: 110px; height: 40px;
            vertical-align: middle; cursor: pointer;}
         .login-btn{
            width: 100%; font-size: 24px; height: 50px;
            background: -moz-linear-gradient(to right, #037dfb 0%,#4aa2fd 100%);
            background: -webkit-linear-gradient(to right, #037dfb 0%,#4aa2fd 100%);
            background: -o-linear-gradient(to right, #037dfb 0%,#4aa2fd 100%);
            background: -ms-linear-gradient(to right, #037dfb 0%,#4aa2fd 100%);
            background: linear-gradient(to right, #037dfb 0%,#4aa2fd 100%);
         }
      }
      .el-form-item{ margin-bottom:40px}
      .login-tips{ font-size: 13px; color: #F56C6C; margin-top: 3px }
      .forget_pwd{
         position: relative;
         .lang-box {
            box-sizing: border-box;
            width: 90px;
            float: right;
            margin-top: 10px;
         }
         .forget-box{
            display: inline-block;
            text-align: right;
            margin-top: 10px;
            .a-label{
               text-decoration: none;
               color:#409eff;
               &:hover{
                  text-decoration: underline;
               }
            }
         }
      }
   }
   .copyright{
      position: fixed;
      bottom: 0;
      text-align: center;
      width: 100%;
      line-height: 4rem;
      font-size: 0.8rem;
      background: #ededed;
   }
}
</style>
